<template>
  <v-app>
    <v-app-bar color="pink darken-2" dark>
      <v-toolbar-title>Amor Digital</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text>Amor</v-btn>
      <v-btn text>Amistad</v-btn>
      <v-btn text>Familia</v-btn>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
    font-family: 'charline';
    src: url('/Charline-font.otf') format('opentype');
  }
.charline {
  font-family: 'charline' !important;
}
.titulo {
  font-size: 16pt;
  color: #ff0000;
}
</style>
