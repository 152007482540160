<template>
  <v-app style="height: 100vh">
    <!-- Contenedor principal -->
    <v-main style="height: 100%">
      <v-container>
        <!-- Tarjeta de introducción -->
        <v-row class="white rounded-xl d-flex justify-center elevation-5 my-2 pink lighten-3 pl-md-15 pt-md-10">
          <v-col cols="12" md="6">
            <v-card  class="mb-15 elevation-0 pink lighten-4 mx-auto">
              <v-card-title
                class="text-center"
                style="font-size: 1.5rem; font-weight: bold;"
                >Encuentra la tarjeta perfecta para expresar
                tu amor este San Valentín</v-card-title
              >
              <hr class="pink darken-1"/>
              <v-card-text class="headline pink lighten-4 mt-5">
                Elije un diseño y envía cariño en formato digital. ¡Sorprende a
                esa persona especial con un detalle único y personalizado!
              </v-card-text>
            </v-card>
            <div class="d-flex justify-center" >
            <v-btn color="pink darken-1 mt-md-15" class="text-start white--text" style="width: 100%; height: 50px; font-size: %100;">
              ¡Diseña ahora tu tarjeta de San Valentín!
            </v-btn>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="5" class="d-flex justify-center mt-md-n10">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <v-img
                    :src="require('@/assets/tarjeta.jpg')"
                    class="rounded-xl"
                    height="100%"
                  />
                </div>
                <div class="flip-card-back poema-container">
                  <div class="poema-title charline">Para Samy:</div>
                  <div class="poema-body">
                    Samy, mi sol, mi claro día,<br />
                    diez años a tu lado, pura alegría.<br />
                    Iluminas mi mundo, noche y día,<br />
                    con tu amor, mi vida florecía.<br /><br />
                    Eres mi guía, mi melodía,<br />
                    en este viaje que juntos construía.<br />
                    Gracias, mi amor, por cada día,<br />
                    Samy, mi todo, mi poesía.
                  </div>
                  <div class="poema-remitente">Atte: tu niño Erick</div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <!-- Sección de categorías -->
        <v-row>
          <v-col cols="12">
            <v-card color="pink lighten-3" class="mb-2">
              <v-card-title style="font-size: 1.5rem; font-weight: bold"
                >Categorías de tarjetas</v-card-title
              >
              <v-card-subtitle class="pb-2">
                Explora nuestras categorías y encuentra la inspiración perfecta
                para cada tipo de amor:
              </v-card-subtitle>
              <v-row class="d-flex justify-center">
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  v-for="category in categories"
                  :key="category.title"
                >
                  <v-card color="pink lighten-3" flat tile>
                    <v-card-title>{{ category.title }}</v-card-title>
                    <v-card-text>{{ category.description }}</v-card-text>
                    <v-img :src="category.image" aspect-ratio="1.7"></v-img>
                    <v-card-actions>
                      <v-btn color="white darken-1" @click="$router.push(category.url)" outlined class="ma-auto">Crea tu tarjeta</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "ValentinePage",
  data: () => ({
    categories: [
      {
        title: "Tarjetas Normales",
        description:
          "Elije entre nuestros diseños para personalizar el texto que deseas enviar a esa persona especial.",
        image: require("@/assets/normalcard.webp"),
        url:''
      },
      {
        title: "Tarjeta de Tiempo",
        description:
          "Celebra con una tarjeta que revelará el texto ingresado hasta el 14 de febrero!.",
        image: require("@/assets/timecard.webp"),
      },
      {
        title: "Tarjeta Match",
        description:
          "Envía una tarjeta de forma anónima, si te llega una tarjeta de la misma persona, se revelarán los remitentes!.",
        image: require("@/assets/matchcard.webp"),
      },
    ],
  }),
};
</script>

<style scoped>
.titulo {
  font-size: 16pt;
}
.flip-card {
  width: 320px;
  height: 504px;
  perspective: 2000px;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 28px;
  transition: all 550ms cubic-bezier(0.1, 0.22, 0.8, 1.13);
  transform-style: preserve-3d;
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 28px;
  backface-visibility: hidden;
}
.flip-card-front {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.flip-card-back {
  font-size: 1.25rem;
  text-align: center;
  display: grid;
  place-items: center;
  padding: 32px;
  background-color: #b1cfc8;
  transform: rotateY(180deg);
}
.flip-card:hover .flip-card-inner {
  transform: rotateY(-180deg);
}
.poema-container {
  background-color: #9b59b6; /* Un morado suave y agradable */
  color: #ffffff; /* Color del texto en blanco para contraste */
  padding: 20px; /* Espaciado interno para no tocar los bordes */
  border-radius: 8px; /* Bordes redondeados para suavidad */
  max-width: 600px; /* Ancho máximo del contenedor */
  margin: auto; /* Centrado con margen arriba y abajo */
  font-family: "Georgia", serif; /* Tipo de letra elegante */
  line-height: 1.6; /* Espaciado entre líneas para mejor lectura */
}

.poema-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.poema-body {
  font-size: 18px; /* Tamaño de fuente para el cuerpo del poema */
  text-align: center; /* Centrar el texto del poema */
}
.poema-remitente {
  font-size: 18px; /* Tamaño de fuente para el remitente */
  font-style: italic; /* Estilo de fuente en cursiva para diferenciar */
  margin-top: 2px; /* Espacio antes del remitente */
  text-align: right; /* Alinear el remitente a la derecha */
  padding-right: 20px; /* Espaciado a la derecha para no tocar el borde */
}
</style>
